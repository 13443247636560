.container {
  position: fixed;
  z-index: 11111;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .limit-inner {
    border-radius: 8px;
    background: #ffffff;
    backdrop-filter: blur(10px);
    width: 450px;
    padding: 32px;
  }
  .inner {
    border-radius: 6px;
    background: #ffffff;
    position: relative;
    width: 584px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    .inner-bg {
      width: 100%;
      position: relative;
      padding-top: 24%;
      border-radius: 6px;
      overflow: hidden;
      // &.inner-bg-mobile {
      //   padding-top: 40%;
      // }
      img {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
      }
    }
    .checkbox {
      margin-top: 32px;
      display: flex;
      align-items: center;

      &.checkbot-active {
        & > div {
          background: #2643ff !important;
          svg {
            display: block !important;
          }
        }
      }
      & > div {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #9da2ad;
        border-radius: 4px;
        width: 16px;
        height: 16px;
        border: 1px solid #e7e8e9;
        margin-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          display: none;
        }
        @media (hover: hover) {
          cursor: pointer;

          &:hover {
            background: #f4f5f6;
            border: 1px solid #2643ff;
          }
        }
      }
    }
    .inner-content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .upgray-btn-fra {
      display: flex;
      // justify-content: flex-end;
      height: 40px;
      margin-top: 16px;
      .generate-btn {
        font-size: 13px;
        font-weight: 500;
        background: #f0f1f2;
        border-radius: 20px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        cursor: pointer;
        div {
          margin-left: 4px;
          padding: 0 6px;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          border-radius: 20px;
          display: flex;
          background: rgba(0, 0, 0, 0.2);
          svg {
            margin-right: 4px;
          }
        }
      }
      // .up-btn {
      //   margin-left: auto;
      //   cursor: pointer;
      //   display: flex;
      //   align-items: center;
      //   padding: 0 16px;
      //   font-size: 13px;
      //   font-weight: 500;
      //   color: #ffffff;
      //   border-radius: 20px;
      //   line-height: 24px;
      //   background: linear-gradient(278deg, #ff6812 0%, #ffb94f 99%);
      //   &.up-btn-vip {
      //     background: linear-gradient(90deg, #cd76ff 0%, #6527ff 99%);
      //   }
      //   svg {
      //     margin-right: 6px;
      //   }
      // }
    }
  }
}
.up-btn-limit {
  width: 320px;
  cursor: pointer;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 28px;
  background: linear-gradient(278deg, #ff6812 0%, #ffb94f 99%);
  margin-top: 16px;
  &.up-btn-pro-limit {
    background: linear-gradient(90deg, #cd76ff 0%, #6527ff 99%);
    @media (hover: hover) {
      &:hover {
        background: linear-gradient(90deg, #b468e0 0%, #5922e0 99%);
      }
    }
  }
  svg {
    margin-right: 6px;
  }
  @media (hover: hover) {
    &:hover {
      background: linear-gradient(270deg, #ff5200 0%, #ffaa00 100%);
    }
  }
}
.up-btn {
  width: 320px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 20px;
  line-height: 24px;
  background: linear-gradient(278deg, #ff6812 0%, #ffb94f 99%);
  margin-top: 16px;
  @media (hover: hover) {
    &:hover {
      background: linear-gradient(270deg, #ff5200 0%, #ffaa00 100%);
    }
  }
  &.up-btn-vip {
    margin-top: 16px;
    background: linear-gradient(90deg, #cd76ff 0%, #6527ff 99%);
    @media (hover: hover) {
      &:hover {
        background: linear-gradient(90deg, #b468e0 0%, #5922e0 99%);
      }
    }
  }
  svg {
    margin-right: 6px;
  }
}

.tips {
  margin-top: 8px;
  border-radius: 6px;
  padding: 10px;
  color: #2643ff;
  text-align: center;
  // background: linear-gradient(90deg, #dbeaff 0%, rgba(255, 255, 255, 0) 100%);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.upgrap-des {
  color: #6f757f;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.share-btn {
  color: #414751;
  border-radius: 20px;
  background: #e0f0ff;
  height: 40px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  cursor: pointer;
  svg {
    margin-right: 4px;
    fill: #414751;
    path {
      fill: #414751;
    }
  }
}

.upgrap-gap {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  margin: 16px 0;
  height: 20px;
  color: #9da2ad;
  gap: 16px;
  span {
    line-height: 1;
    width: 20px;
    height: 1px;
    background-color: #dfe5eb;
  }
}

.limit-loading {
  width: 120px;
  height: 75px;
  margin: 0 auto;
}

.title {
  color: #414751;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}
.public-des {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #6f757f;
  line-height: 20px;
  margin-top: 8px;
}
.des {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #6f757f;
  line-height: 20px;
  margin-top: 8px;
  height: 40px;
}

.close {
  position: absolute;
  left: calc(100% + 8px);
  top: 0;
  width: 32px;
  cursor: pointer;
  height: 32px;
  background: #1a1b1c;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  svg {
    fill: #ffffff;
  }
}

.cancel {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 28px;
  background: #f0f1f2;
  border: 1px solid #dfe5eb;
  margin-top: 16px;
  @media (hover: hover) {
    &:hover {
      background: #eaebec;
    }
  }
}

@media (max-width: 750px) {
  .container {
    .close {
      left: 0;
      top: -8px;
      background-color: unset;
      transform: translate(0, -100%);
    }
    .up-btn {
      width: 100% !important;
    }
    .share-btn {
      width: 100% !important;
    }
    .limit-inner {
      width: 90%;
      padding: 24px;
    }
    .inner {
      width: 90%;
      .inner-content {
        padding: 16px;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
      .des {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
      }
      .upgray-btn-fra {
        margin-top: 16px;
      }
    }
  }
}

.upgray-des {
  color: #6f757f;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  margin-top: 8px;
}

.upgray-btn-share {
  cursor: pointer;
  height: 40px;
  color: #414751;
  border-radius: 40px;
  background: linear-gradient(105deg, #e0f0ff 0%, #f9ffe0 99%);
  padding: 0 24px;
  width: fit-content;
  display: flex;
  align-items: center;
  // svg {
  //   margin-right: 4px;
  //   fill: #414751;
  //   path {
  //     fill: #414751;
  //   }
  // }
}

// .up-btn-vip-limit {
//   flex: 1;
//   justify-content: center;
// }
