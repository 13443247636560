@keyframes Rotate {
  0% {
    transform: rotate(0);
  }
  99% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.animation-paly {
  animation: Rotate 1s ease-out;
}
.credits-btn {
  border-radius: 30px;
  height: 40px;
  background: rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;
  padding: 0 4px 0 16px;
  margin-right: 8px;

  .circle {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 0 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
    svg {
      width: 16px;
    }
  }
  .refresh-credits {
    border-radius: 16px;
    cursor: pointer;
    height: 32px;
    white-space: nowrap;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #66deff 3%, #ffeb66 100%);
    border: 2px solid #ffffff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: normal;
    color: #095a4b;
    max-width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    @media (hover: hover) {
      &:hover {
        background: linear-gradient(90deg, #adedff 3%, #fff5b6 100%);
      }
    }
  }
}

.credits-content {
  p {
    text-align: center;
    margin-bottom: 0;
    &:nth-child(1) {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      letter-spacing: normal;
      color: #ffffff;
    }
    &:nth-child(2) {
      font-size: 12px;
      font-weight: normal;
      line-height: 14px;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
}

@media (max-width: 750px) {
  .credits-btn {
    .circle {
      margin: 0 2px;
    }
    .refresh-credits {
      // width: 32px;
      height: 32px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .credits-content {
    p {
      text-align: center;
      &:nth-child(1) {
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
      }
      &:nth-child(2) {
        font-size: 10px;
        font-weight: normal;
        line-height: 10px;
      }
    }
  }
}
